<template>
  <el-form>
    <el-row type="flex" justify="space-around">
      <el-col :span="7">
        <el-form-item label="">
          <el-select v-model="form.first_cat" placeholder="所有大类" @change="changeFirstCat" clearable>
            <el-option v-for="item in first_cat_list" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="">
          <el-select v-model="form.second_cat" placeholder="所有小类" @change="getList" clearable>
            <el-option v-for="item in second_cat_list" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="">
          <el-select v-model="form.region" placeholder="所有区域" @change="getList" clearable>
            <el-option v-for="item in region_list" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div class="cards flex-center-center flex-column flex-wrap" v-if="list.length > 0">
    <tl-card v-for="item in list" :key="item.tool_id" :dicts="dicts" :tool="item"></tl-card>
  </div>
  <el-empty description="什么都没有" v-if="list.length == 0" />
</template>

<script>
import ToolApi from '@/utils/api/tool'
import UserApi from '@/utils/api/user'
import { transferDict, getDict } from '@/utils/utils'
import { useDictStore } from '@/stores/useDictStore'

export default {
  name: 'Damage',
  data() {
    return {
      form: {
        first_cat: '',
        second_cat: '',
        region: '',
      },
      first_cat_list: [],
      second_cat_list: [],
      region_list: [],
      list: [],
      dicts: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const dictStore = useDictStore()
      this.dicts = await dictStore.getDict()
      this.first_cat_list = getDict(this.dicts, 'tool_cat').filter((item) => !item.parentId)
      this.region_list = getDict(this.dicts, 'tool_region')
    },
    async changeFirstCat(value) {
      if (!value) {
        this.second_cat_list = []
        this.form.second_cat = ''
      } else {
        this.second_cat_list = getDict(this.dicts, 'tool_cat').filter((item) => item.parentId == value)
      }
      await this.getList()
    },
    async getList() {
      try {
        const res = await ToolApi.getDamageData(this.form)
        this.list = res?.data || []
      } catch (err) {
        this.$message({
          message: err?.response?.data?.message || '服务器异常',
          type: 'error',
        })
      }
    },
  },
}
</script>

<style scoped>
:deep(.el-select__wrapper) {
  border-radius: 20px !important;
}

.cards > div {
  margin: 2rem 0;
}

.cards {
  margin-bottom: 150px;
}
</style>
