<template>
  <tl-header title="维修完成"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="维修方式" prop="mode">
        <el-col :span="22">
          <el-select v-model="form.mode" placeholder="选择维修方式" disabled>
            <el-option v-for="item in modeEnum" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="维修时间" required>
        <el-col :span="22">
          <el-form-item prop="maintenance_time">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.maintenance_time" style="width: 100%" value-format="YYYYMMDD"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="损坏部位：选择工具部件（多选）" prop="damage_part">
        <el-col :span="22">
          <el-select v-model="form.damage_part" placeholder="请选择工具部件" multiple disabled>
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name" :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="损失部位：选择工具部件（多选）" prop="lose_part">
        <el-col :span="22">
          <el-select v-model="form.lose_part" placeholder="请选择工具部件" multiple disabled>
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name" :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="维修供应商" prop="maintenance_company">
        <el-col :span="22">
          <el-input v-model="form.maintenance_company" placeholder="请填写名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="维修价格" prop="price">
        <el-col :span="22">
          <el-input v-model.number="form.price" placeholder="请填写价格">
            <template #append>
              <div>￥</div>
            </template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="附件上传" prop="maintenance_file" required>
        <el-upload
          class="upload-demo"
          :auto-upload="false"
          :on-change="handleAttachmentChange"
          :file-list="form.maintenance_file"
          multiple
          :limit="4"
          accept=".jpg,.png,.pdf"
          :on-exceed="handleExceed"
          list-type="picture-card"
        >
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'
import ToolApi from '@/utils/api/tool'
import { useDictStore } from '@/stores/useDictStore'
import { useUserStore } from '@/stores/useUserStore'

export default {
  name: '',

  props: {},
  data() {
    return {
      title: '维修完成',
      flow: {},
      toolPartsList: [],
      form: {
        flow_id: '',
        mode: '',
        maintenance_company: '',
        maintenance_time: '',
        damage_part: [],
        lose_part: [],
        price: '',
        maintenance_file: [],
      },
      rules: {
        maintenance_time: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
        maintenance_company: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
          { type: 'number', message: '价格必须为数字值', trigger: 'blur' },
        ],
        maintenance_file: [{ required: true, message: '请上传附件', trigger: 'blur' }],
      },
      limit: 4,
      modeEnum: [],
      maintenanceInfo: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const flow_id = this.$route.query?.flow_id
      const tool_id = this.$route.query?.tool_id
      if (flow_id || tool_id) {
        this.form.flow_id = flow_id
        const dictStore = useDictStore()
        this.dicts = await dictStore.getDict()
        const userStore = useUserStore()
        this.user = userStore.user
        const res = await ProcessApi.getFlowDetail(flow_id)
        this.flow = res.data[0]
        this.toolPartsList = await ToolApi.getPartList(tool_id)

        this.modeEnum = this.dicts.find((v) => v.dicNo == 'tool_maintenance')?.data
        this.maintenanceInfo = (await ToolApi.getFlowMaintenances(flow_id))[0]
        if (!this.maintenanceInfo) {
          this.$message.error('维修流程数据异常')
        } else {
          this.form.mode = String(this.maintenanceInfo?.mode)
          const parts = await ProcessApi.getFlowDamageData(flow_id)
          this.form.damage_part = parts.data
            .filter((v) => v.is_damage)
            .map((item) => item.damage_part)
            .reduce((acc, val) => acc.concat(val.split(',').map(Number)), [])
          console.log(this.form.damage_part)
          this.form.lose_part = parts.data
            .filter((v) => !v.is_damage)
            .map((item) => item.damage_part)
            .reduce((acc, val) => acc.concat(val.split(',').map(Number)), [])
        }
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error',
        })
        this.$router.back()
      }
    },
    back() {
      this.$router.back()
    },
    handleExceed(files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`)
    },
    handleAttachmentChange(file, fileList) {
      this.form.maintenance_file = fileList
    },
    submitForm() {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form)
          this.form.damage_part = this.form.damage_part.join(',')
          this.form.lose_part = this.form.lose_part.join(',')
          ProcessApi.maintenanceCompleted(this.form)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })
                this.$router.back()
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                })
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              })
            })
        } else {
          // 表单校验不通过
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate > div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>
