<template>
  <div class="attachment flex-center-center">
    <img src="@/assets/images/word-icon.png" alt="" v-if="type === 'word'">
    <img src="@/assets/images/word-icon.png" alt="" v-else-if="type === 'pdf'">
    <img :src="path" alt="" v-else-if="type === 'image'">
    <p class="attachment-name one-ellipsis">{{name}}</p>
  </div>
</template>

<script>

export default {
  name: 'Attachment',

  props: {
    name: String,
    type: String,
    path: String
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style scoped>
.attachment {
  width: 22vw;
  height: 22vw;
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  white-space: nowrap;
  margin: 0.4rem 0.4rem;
  cursor: pointer;
  position: relative;
  background-image: url('@/assets/images/cover.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.attachment img {
  width: 60%;
}

.attachment-name {
  width: 80%;
  position: absolute;
  bottom: 0.4rem;
  color: white;
  font-size: 1.2rem;
}
</style>