<template>
  <tl-header title="校验完成"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="校验时间" required>
        <el-col :span="22">
          <el-form-item prop="check_time">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.check_time" style="width: 100%;"
              value-format="YYYYMMDD" @change="changeCheckTime"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="校验供应商" prop="check_supplier">
        <el-col :span="22">
          <el-input v-model="form.check_supplier" placeholder="请填写名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="校验证书" prop="check_certificate" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleCertificateChange"
          :file-list="form.check_certificate" multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed"
          list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="下次校验时间">
        <el-col :span="22">
          <el-form-item>
            <el-date-picker type="date" placeholder="选择日期" v-model="nextVerifyDate" style="width: 100%;"
              value-format="YYYYMMDD" disabled></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'
import UserApi from '@/utils/api/user'
import ToolApi from '@/utils/api/tool'
import { addDates } from '@/utils/moment';
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';


export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '新建维修',
      form: {
        flow_id: '',
        check_supplier: '',
        check_time: '',
        check_certificate: []
      },
      nextVerifyDate: '',
      tool: {},
      dicts: [],
      rules: {
        check_time: [
          { type: 'string', required: true, message: '请选择维修时间', trigger: 'change' }
        ],
        check_supplier: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
      },
      limit: 4
    }
  },

  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$route.query?.flow_id || this.$route.query?.tool_id) {
        this.form.flow_id = this.$route.query?.flow_id;
        const dictStore = useDictStore();
        this.dicts = await dictStore.getDict();
        const userStore = useUserStore();
        this.user = userStore.user;
        const processRes = await ProcessApi.getFlowDetail(this.$route.query?.flow_id)
        this.flow = processRes.data[0]
        this.modeEnum = this.dicts.find(v => v.dicNo == 'tool_check')?.data

        const toolRes = await ToolApi.getToolDetail(this.$route.query?.tool_id)
        this.tool = toolRes.data?.[0]
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error'
        })
        this.$router.back();
      }

    },
    changeCheckTime (val) {
      this.nextVerifyDate = addDates(val, this.tool.verification_cycle);
    },
    back () {
      this.$router.back();
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    handleCertificateChange (file, fileList) {
      this.form.check_certificate = fileList;
    },
    submitForm () {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          ProcessApi.toolCheckCompleted(this.form).then(res => {
            if (res.status) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              this.$router.back();
            } else {
              this.$message({
                message: res.message || '提交失败',
                type: 'error'
              })
            }
          }).catch(err => {
            this.$message({
              message: err.message || '提交失败',
              type: 'error'
            })
          })
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>