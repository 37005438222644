<template>
  <tl-header title="损坏维修"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="维修方式" prop="mode" required>
        <el-select v-model="form.mode" placeholder="选择维修方式" clearable>
          <el-option v-for="item in modeEnum" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="损坏部位：选择工具部件（多选）" prop="damage_part">
        <el-col>
          <el-select v-model="form.damage_part" placeholder="选择工具部件" @change="change" multiple>
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name" :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="遗失部位：选择工具部件（多选）" prop="lose_part">
        <el-col>
          <el-select v-model="form.lose_part" placeholder="选择工具部件" @change="change" multiple>
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name" :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="维修商公司" prop="maintenance_company" :required="form.mode == 1">
        <el-input v-model="form.maintenance_company" placeholder="请填写维修商公司"></el-input>
      </el-form-item>

      <el-form-item label="物流单号" prop="express_delivery_number" :required="form.mode == 2">
        <el-input v-model="form.express_delivery_number" placeholder="请填写物流单号"></el-input>
      </el-form-item>
      <el-form-item label="寄出快递单照片" prop="express_delivery_img" :required="form.mode == 2">
        <el-upload
          class="upload-demo"
          :auto-upload="false"
          :on-change="handleDeliveryChange"
          :file-list="form.express_delivery_img"
          multiple
          :limit="4"
          accept=".jpg,.png,.pdf"
          :on-exceed="handleExceed"
          list-type="picture-card"
        >
        </el-upload>
      </el-form-item>

      <el-form-item label="破损图片" prop="tool_img" required>
        <el-upload
          class="upload-demo"
          :auto-upload="false"
          :on-change="handleToolImg"
          :file-list="form.tool_img"
          multiple
          :limit="4"
          accept=".jpg,.png,.pdf"
          :on-exceed="handleExceed"
          list-type="picture-card"
        >
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import { transferDict } from '@/utils/utils'
import ProcessApi from '@/utils/api/process'
import UserApi from '@/utils/api/user'
import ToolApi from '@/utils/api/tool'
import { useDictStore } from '@/stores/useDictStore'
import { useUserStore } from '@/stores/useUserStore'

export default {
  name: '',
  props: {},
  data() {
    return {
      title: '损坏维修',
      form: {
        flowid: '',
        mode: '',
        damage_part: [],
        lose_part: [],
        maintenance_company: '',
        express_delivery_number: '',
        tool_img: [],
        express_delivery_img: [],
      },
      toolPartsList: [],
      dicts: [],
      flow: [],
      modeEnum: [],
      rules: {
        mode: [{ required: true, message: '请选择维修方式', trigger: 'blur' }],
        damage_part: [{ validator: this.validateOneRequired, trigger: 'change' }],
        lose_part: [{ validator: this.validateOneRequired, trigger: 'change' }],
        maintenance_company: [{ required: true, message: '请输入维修商公司', trigger: 'blur' }],
        express_delivery_number: [{ required: true, message: '请输入物流单号', trigger: 'blur' }],
        express_delivery_img: [{ required: true, message: '请上传快递单图片', trigger: 'blur' }],
        tool_img: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
      limit: 4,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.$route.query?.flow_id || this.$route.query?.tool_id) {
        this.form.flowid = this.$route.query?.flow_id

        const dictStore = useDictStore()
        this.dicts = await dictStore.getDict()
        const userStore = useUserStore()
        this.user = userStore.user
        const res = await ProcessApi.getFlowDetail(this.$route.query?.flow_id)
        this.flow = res.data[0]
        this.borrowInfo = await ProcessApi.getBorrowInfoByFlowId(this.$route.query?.flow_id)
        this.toolPartsList = await ToolApi.getPartList(this.$route.query?.tool_id)

        this.modeEnum = this.dicts.find((v) => v.dicNo == 'tool_maintenance')?.data
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error',
        })
        this.$router.back()
      }
    },
    // 自定义校验规则，至少一个选择框非空
    validateOneRequired(rule, value, callback) {
      if (this.toolPartsList.length > 0 && !this.form.damage_part?.length > 0 && !this.form.lose_part?.length > 0) {
        callback(new Error('遗失部位和损坏部位至少选择一个'))
      } else {
        callback() // 校验通过
      }
    },
    back() {
      this.$router.back()
    },
    handleExceed(files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`)
    },

    handleDeliveryChange(file, fileList) {
      this.form.express_delivery_img = fileList
    },
    handleToolImg(file, fileList) {
      this.form.tool_img = fileList
    },
    change() {},
    submitForm() {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form)
          this.form.damage_part = this.form.damage_part.join(',')
          this.form.lose_part = this.form.lose_part.join(',')
          ProcessApi.addMaintenance(this.form)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })
                this.$router.back()
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                })
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              })
            })
        } else {
          // 表单校验不通过
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate > div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>
