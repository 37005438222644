<template>
  <footer>
    <div class="footer">
      <div class="footer-menu" @click="navigateTo('home')" :class="{ active: activeMenu === 'home' }">
        <i class="fas fa-home fa-lg"></i>
        <span>首页</span>
      </div>
      <div class="footer-menu" @click="navigateTo('process')" :class="{ active: activeMenu === 'process' }">
        <i class="fas fa-cogs fa-lg"></i>
        <span>流程</span>
      </div>
      <div class="footer-menu" @click="navigateTo('tool')" :class="{ active: activeMenu === 'tool' }">
        <i class="fas fa-tools fa-lg"></i>
        <span>工具</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'ToolFooter',
  props: ['activeMenu'],
  data () {
    return {
    };
  },
  created () {
    // 设置默认的activeMenu值
    // console.log(this.$route);
    // this.activeMenu = this.$route.name || 'home';
  },
  methods: {
    navigateTo (routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 0 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid #d8d8d8;
}

.footer-menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-menu i {
  margin-bottom: 10px;
  /* 图标和文字之间的间距 */
}

.active {
  color: #0068b7;
  /* 活动菜单的文字颜色 */
}
</style>