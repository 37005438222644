<template>
  <tl-header title="回寄信息填写"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="物流公司" prop="company" required>
        <el-col>
          <el-input v-model="form.company" placeholder="请填写物流公司">
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="物流单号" prop="no" required>
        <el-col>
          <el-input v-model="form.no" placeholder="请填写物流单号">
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="快递单图片" prop="delivery_note_images" required>
        <el-upload class="upload-demo" action="/upload" :before-upload="beforeUpload" :on-success="handleSuccess"
          :on-error="handleError" :file-list="form.delivery_note_images" multiple :limit="4" accept=".jpg,.png,.pdf"
          :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="邮寄工具图片" prop="tool_images" required>
        <el-upload class="upload-demo" action="/upload" :before-upload="beforeUpload" :on-success="handleSuccess"
          :on-error="handleError" :file-list="form.tool_images" multiple :limit="4" accept=".jpg,.png,.pdf"
          :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-divider></el-divider>
    </el-form>
  </div>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>

export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '回寄信息填写',
      form: {
        compnay: '',
        no: '',
        delivery_note_images: [
          { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
          { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
        ],
        tool_images: [
          { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
          { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
        ]
      },
      rules: {
        company: [
          { required: true, message: '请输入物流公司', trigger: 'blur' }
        ],
        no: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
        ],
        delivery_note_images: [{ required: true, message: '请上传快递单', trigger: 'blur' }],
        tool_images: [{ required: true, message: '请上传工具照片', trigger: 'blur' }]
      },
      limit: 4
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.back();
    },
    beforeUpload (file) {
      // 在上传之前的处理，例如：文件类型、大小限制等
      console.log('beforeUpload', file);
    },
    handleSuccess (response, file, fileList) {
      // 上传成功的处理
      console.log('handleSuccess', response, file, fileList);
    },
    handleError (err, file, fileList) {
      // 上传失败的处理
      console.error('handleError', err, file, fileList);
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    submitForm () {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
  min-height: calc(90vh - 14rem);
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}

:deep(.el-checkbox__inner) {
  margin-right: 1rem;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>