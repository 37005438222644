<template>
  <tl-header title="盘点"></tl-header>
  <div>
    <el-table :data="PageDataEx" style="width: 100%" stripe>
      <el-table-column prop="eventName" label="流程" />
      <el-table-column prop="status_flow" label="状态" />
      <el-table-column prop="active_user_name" label="操作人" />
      <el-table-column prop="remark" label="备注" />
      <el-table-column prop="create_time" label="时间" width="120" />
    </el-table>
    <el-row justify="center">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="changePage" />
    </el-row>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process';
import ToolApi from '@/utils/api/tool';
import { transferDict } from '@/utils/utils';
import { useDictStore } from '@/stores/useDictStore';

export default {
  data() {
    return {
      tool: {},
      PageDataEx: [],
      dicts: [],
      total: 0,
      pageSize: 10,
      page: 1,
    };
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      const tool_id = this.$route.query?.tool_id;
      if (tool_id) {
        const dictStore = useDictStore();
        this.dicts = await dictStore.getDict();
        const toolDetail = await ToolApi.getToolDetail(tool_id);
        this.tool = toolDetail.data?.[0];
        await this.getPageDataEx();
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error',
        });
        this.$router.back();
      }
    },

    async changePage(val) {
      this.page = val;
      await this.getPageDataEx();
    },
    async getPageDataEx() {
      const PageDataExRes = await ProcessApi.getPageDataEx(this.tool.tool_id, this.pageSize, this.page);
      this.total = PageDataExRes.total;
      this.PageDataEx = PageDataExRes.rows.map((v) => {
        const active_user_name = PageDataExRes.extra[v.active_user];
        const status_flow =
          v.old_status !== v.now_status && v.type < 5
            ? transferDict(this.dicts, `tool_flow_status${v.type}`, v.old_status) +
              '->' +
              transferDict(this.dicts, `tool_flow_status${v.type}`, v.now_status)
            : transferDict(this.dicts, `tool_flow_status${v.type}`, v.now_status);
        return {
          ...v,
          active_user_name,
          status_flow,
        };
      });
    },
  },
};
</script>

<style scoped>
:deep(.el-pagination) {
  margin: 10px 0;
}
</style>
