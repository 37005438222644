<template>
  <div :class="[tag, type]">{{ this.name }}</div>
</template>

<script>

export default {
  name: 'Tag',

  props: {
    name: String,
  },
  data () {
    return {
      tag: 'tag', // 基础类名
      TagEnum: [
        {
          name: '可预约',
          class: 'gray-tag'
        },
        {
          name: '可借调',
          class: 'gray-tag'
        },
        {
          name: '预约',
          class: 'green-tag'
        },
        {
          name: '已预约',
          class: 'green-tag'
        },
        {
          name: '借调',
          class: 'blue-tag'
        },
        {
          name: '已借调',
          class: 'blue-tag'
        },
        {
          name: '使用中',
          class: 'use-blue-tag'
        },
        {
          name: '校验',
          class: 'orange-tag'
        },
        {
          name: '校验中',
          class: 'orange-tag'
        },
        {
          name: '待寄回',
          class: 'red-tag'
        },
        {
          name: '维修中',
          class: 'red-tag'
        },
        {
          name: '维修',
          class: 'red-tag'
        },
      ]
    }
  },
  created () {
  },
  methods: {

  },
  computed: {
    type () {
      return this.TagEnum.find(v => v.name === this.name)?.class || 'common-tag';
    }
  }
}
</script>

<style scoped>
.tag {
  border-radius: 20px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  white-space: nowrap;
  margin: 0 0.3rem;
}

.blue-tag {
  background: #d2f0fb;
  color: #087aa7;
}
.use-blue-tag {
  background: #0068b7;
  color: white;
}

.green-tag {
  background: #e1f7f3;
  color: #0daa90;
}

.orange-tag {
  background: #fff0d6;
  color: #f4a31e;
}

.red-tag {
  background: #ffe6ea;
  color: #cd4a5f;
}

.gray-tag {
  background: #e9e9e9;
  color: black;
}
.common-tag {
  border: 1px solid black;
  background: white;
  color: black;
}
</style>