import UserApi from '@/utils/api/user';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    token: null,
    user: null,
  }),

  getters: {},

  actions: {
    async login(appId) {
      const res = await UserApi.login(appId);
      this.user = res.data;
      this.token = res.data?.token;
      return this.user;
    },

    async loginByCode(code) {
      const res = await UserApi.getWxUserByCode(code);
      this.user = res.data;
      this.token = res.data?.token;
      return this.user;
    },
    async getUserInfo() {
      if (this.user) return this.user;
      this.user = await UserApi.lo();
      return this.user;
    },
    clear() {
      this.token = null;
      this.user = null;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user', // 存储时的键名，可以自定义
        storage: localStorage, // 使用 localStorage 进行持久化
      },
    ],
  },
});
