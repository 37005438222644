<template>
  <div class="selects">
    <el-form>
      <el-row type="flex" justify="start" :gutter="20">
        <el-col :span="10">
          <el-form-item label="">
            <el-select v-model="form.type" placeholder="所有类型" @change="change" clearable>
              <el-option v-for="item in typeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="">
            <el-select v-model="form.status" placeholder="流程状态" @change="getList" clearable>
              <el-option v-for="item in statusList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <div class="cards flex-center-center flex-column flex-wrap" v-if="list.length > 0">
    <ps-card v-for="item in list" :key="item.flow_id" :dicts="dicts" :flow="item"></ps-card>
  </div>
  <el-empty description="什么都没有" v-if="list.length == 0" />
</template>

<script>
import UserApi from '@/utils/api/user'
import { transferDict, getDict } from '@/utils/utils'
import ProcessApi from '@/utils/api/process'
import { useDictStore } from '@/stores/useDictStore'

export default {
  name: 'Todo',
  data() {
    return {
      form: {
        type: '',
        status: '',
      },
      typeList: [],
      statusList: [],
      list: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const dictStore = useDictStore()
      this.dicts = await dictStore.getDict()
      this.typeList = getDict(this.dicts, 'tool_action_status')
    },
    async change(value) {
      if (value) {
        this.statusList = getDict(this.dicts, 'tool_flow_status' + value)
      } else {
        this.form.status = ''
        this.statusList = []
      }
      await this.getList()
    },
    async getList() {
      const res = await ProcessApi.getFlowTodoData(this.form)
      this.list = res?.data || []
    },
  },
}
</script>

<style scoped>
:deep(.el-select__wrapper) {
  border-radius: 20px !important;
}

.selects {
  width: 90vw;
  margin: 0 auto;
}

.cards {
  margin-bottom: 150px;
}

.cards > div {
  margin-bottom: 2rem;
}
</style>
