<template>
  <tl-header title="校验工具"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="校验方式" prop="mode" required>
        <el-select v-model="form.mode" placeholder="请选择校验方式" clearable>
          <el-option v-for="item in modeEnum" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校验厂家" prop="check_company" required>
        <el-input v-model="form.check_company" placeholder="请填写厂家"></el-input>
      </el-form-item>
      <el-form-item label="寄出物流公司" prop="express_delivery_company" :required="form.mode == 2">
        <el-input v-model="form.express_delivery_company" placeholder="请填写物流公司"></el-input>
      </el-form-item>
      <el-form-item label="寄出物流单号" prop="express_delivery_number" :required="form.mode == 2">
        <el-input v-model="form.express_delivery_number" placeholder="请填写物流单号"></el-input>
      </el-form-item>

      <el-form-item label="回寄时地址" prop="return_shipping_address" :required="form.mode == 2">
        <el-input v-model="form.return_shipping_address" placeholder="请填写地址"></el-input>
      </el-form-item>

      <el-form-item label="回寄时收货人" prop="return_shipping_name" :required="form.mode == 2">
        <el-input v-model="form.return_shipping_name" placeholder="请填写收货人"></el-input>
      </el-form-item>

      <el-form-item label="回寄时手机号" prop="return_shipping_phone" :required="form.mode == 2">
        <el-input v-model="form.return_shipping_phone" placeholder="请填写手机号"></el-input>
      </el-form-item>
      <el-form-item label="寄出快递单照片" prop="express_delivery_img" :required="form.mode == 2">
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleDeliveryImgChange"
          :file-list="form.express_delivery_img" multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed"
          list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="工具图" prop="tool_img" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleToolImgChange" :file-list="form.tool_img"
          multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'
import UserApi from '@/utils/api/user'
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';

export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '校验工具',
      form: {
        flow_id: '',
        mode: '',
        check_company: '',
        express_delivery_company: '',
        express_delivery_number: '',
        return_shipping_address: '',
        return_shipping_name: '',
        return_shipping_phone: '',
        tool_img: [],
        express_delivery_img: [],


      },
      dicts: [],
      modeEnum: [],
      rules: {
        mode: [
          { required: true, message: '请选择校验方式', trigger: 'blur' }
        ],
        check_company: [
          { required: true, message: '请填写厂家', trigger: 'blur' }
        ],
        express_delivery_company: [
          { required: true, message: '请选择物流公司', trigger: 'blur' }
        ],
        express_delivery_number: [
          { required: true, message: '请填写物流单号', trigger: 'blur' }
        ],
        return_shipping_address: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ],
        return_shipping_name: [
          { required: true, message: '请填写姓名', trigger: 'blur' }
        ],
        return_shipping_phone: [
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        express_delivery_img: [{ required: true, message: '请上传快递单照片', trigger: 'blur' }],
        tool_img: [{ required: true, message: '请上传工具图', trigger: 'blur' }]
      },
      limit: 4
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$route.query?.flow_id || this.$route.query?.tool_id) {
        this.form.flow_id = this.$route.query?.flow_id;
        await this.getToolDict()
        const userStore = useUserStore();
        this.user = userStore.user;
        const res = await ProcessApi.getFlowDetail(this.$route.query?.flow_id)
        this.flow = res.data[0]
        this.modeEnum = this.dicts.find(v => v.dicNo == 'tool_check')?.data
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error'
        })
        this.$router.back();
      }

    },
    async getToolDict () {
      const dictStore = useDictStore();
      this.dicts = await dictStore.getDict();
    },
    back () {
      this.$router.back();
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    handleDeliveryImgChange (file, fileList) {
      this.form.express_delivery_img = fileList;
    },
    handleToolImgChange (file, fileList) {
      this.form.tool_img = fileList;
    },
    submitForm () {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          ProcessApi.addToolCheck(this.form).then(res => {
            if (res.status) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
              this.$router.back();
            } else {
              this.$message({
                message: res.message || '提交失败',
                type: 'error'
              })
            }
          }).catch(err => {
            this.$message({
              message: err.message || '提交失败',
              type: 'error'
            })
          })
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>