<template>
  <tl-header title="确认收货"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="工具状态" prop="RecivedToolStatus">
        <el-col>
          <el-select v-model="form.RecivedToolStatus" placeholder="选择工具状态" @change="change" clearable>
            <el-option label="完好" value="1"></el-option>
            <el-option label="缺损" value="2"></el-option>
            <el-option label="丢失" value="3"></el-option>
            <el-option label="缺损但可正常使用" value="4"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="工具图" prop="ToolImgs" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleToolChange" :file-list="form.ToolImgs"
          multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="缺损部位：选择工具部件（多选）" prop="DamageParts" :required="form.RecivedToolStatus == 2">
        <el-col>
          <el-select v-model="form.DamageParts" placeholder="选择工具部件" @change="change" multiple
            :disabled="form.RecivedToolStatus == 1">
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name"
              :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="遗失部位：选择工具部件（多选）" prop="LoseParts" :required="form.RecivedToolStatus == 2">
        <el-col>
          <el-select v-model="form.LoseParts" placeholder="选择工具部件" @change="change" multiple
            :disabled="form.RecivedToolStatus == 1">
            <el-option v-for="item in toolPartsList" :key="item.part_id" :label="item.part_name"
              :value="item.part_id"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item prop="checked" required>
        <el-checkbox v-model="form.checked" />请查看《注意事项》内容，并确保工具满足要求。
      </el-form-item>
    </el-form>
  </div>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'
import ToolApi from '@/utils/api/tool'
export default {
  name: '',

  props: {},
  data () {
    return {
      title: '填写物流信息',
      form: {
        flowid: '',
        RecivedToolStatus: '',
        DamageParts: [],
        LoseParts: [],
        checked: '',
        ToolImgs: [],
      },
      tool_id: '',
      toolPartsList: [],
      rules: {
        RecivedToolStatus: [{ required: true, message: '请选择工具状态', trigger: 'blur' }],
        DamageParts: [{ required: true, message: '请选择工具部件', trigger: 'blur' }],
        LoseParts: [{ required: true, message: '请选择工具部件', trigger: 'blur' }],
        ToolImgs: [{ required: true, message: '请上传工具图', trigger: 'blur' }],
        checked: [{ required: true, message: '请确认注意事项', trigger: 'blur' }],
      },
      limit: 4,
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$route.query?.flow_id && this.$route.query?.tool_id) {
        this.form.flowid = this.$route.query?.flow_id
        this.tool_id = this.$route.query?.tool_id
        this.toolPartsList = await ToolApi.getPartList(this.tool_id)
        console.log(this.toolPartsList);
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error'
        })
        this.$router.back();
      }
    },
    back () {
      this.$router.back()
    },
    handleToolChange (file, fileList) {
      this.form.ToolImgs = fileList;
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`)
    },
    submitForm () {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form)
          ProcessApi.takeOver(this.form).then(res => {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.back();
          }).catch(err => {
            this.$message({
              message: err.message || '提交失败',
              type: 'error'
            })
          })
        } else {
          // 表单校验不通过
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
  min-height: calc(90vh - 14rem);
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}

:deep(.el-checkbox__inner) {
  margin-right: 1rem;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>