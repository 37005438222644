<template>
  <tl-header title="流程详情"></tl-header>
  <div class="detail-body">
    <div class="flex-between-center detail-body-head">
      <div class="title">
        {{ flow.tool_name }}
      </div>
      <div class="tags flex-start-center">
        <tag :name="transferType(flow?.flow_type)"></tag>
      </div>
    </div>
    <div class="detail-body-info">
      <el-collapse accordion v-model="collapse">
        <el-collapse-item name="1">
          <template v-slot:title>
            <div class="text">所属大区：{{ flow.region_name }}</div>
          </template>
          <div class="text">工具归属者：{{ flow.t_ownername }}</div>
          <div class="text">当前持有者：{{ flow.t_username }}</div>
          <div class="tips">
            <div>发起者：{{ flow.f_username }}</div>
            <div>发起时间：{{ flow.create_time }}</div>
            <div>发起者所属大区：{{ flow.region_name }}</div>
            <div>
              流程状态：<span :class="theme">{{ transferStatus(flow?.flow_status) }}</span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="detail-body-tool-info" v-if="flow.flow_type == 1 && !!borrowInfo">
      <div class="title blue">借调信息</div>
      <div class="main">
        <div class="text">
          借调使用时间：{{ new Date(borrowInfo?.start_time).toLocaleDateString() }}～{{
            new Date(borrowInfo?.end_time).toLocaleDateString()
          }}
        </div>
        <div class="text">收件人：{{ borrowInfo.to_user }}</div>
        <div class="text">借调工程师：{{ flow?.t_secondname }}</div>
        <div class="text">联系电话：{{ borrowInfo.phone }}</div>
        <div class="text">收件地址：{{ borrowInfo.address }}</div>
        <div class="tips">备注：{{ flow?.remark }}</div>
      </div>
      <div class="detail">
        <tl-button name="工具详情" type="black" @click="navigateTo('toolDetail')"></tl-button>
      </div>
    </div>

    <div class="detail-body-tool-info" v-if="flow.flow_type == 2 && !!borrowInfo">
      <div class="title green">预约信息</div>
      <div class="main">
        <div class="text">
          预约使用时间：{{ new Date(borrowInfo?.start_time).toLocaleDateString() }}～{{
            new Date(borrowInfo?.end_time).toLocaleDateString()
          }}
        </div>
        <div class="text">收件人：{{ borrowInfo.to_user }}</div>
        <div class="text">预约工程师：{{ borrowInfo.to_user }}</div>
        <div class="text">联系电话：{{ borrowInfo.phone }}</div>
        <div class="text">收件地址：{{ borrowInfo.address }}</div>
        <div class="tips">备注：{{ flow?.remark }}</div>
      </div>
      <div class="detail">
        <tl-button name="工具详情" type="black" @click="navigateTo('toolDetail')"></tl-button>
      </div>
    </div>
    <div class="detail-body-tool-info" v-if="!!deliveryInfo">
      <div class="title blue">物流信息</div>
      <div class="main">
        <div class="text">物流公司：{{ deliveryInfo.express_delivery_company }}</div>
        <div class="text">物流单号：{{ deliveryInfo.express_delivery_number }}</div>
        <div class="text">快递单照片：</div>
        <div class="images flex-start-center flex-wrap">
          <div v-for="(path, index) in deliveryInfo?.express_delivery_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
        <div class="text">工具图：</div>
        <div class="images flex-start-center flex-wrap">
          <div v-for="(path, index) in deliveryInfo?.tool_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div class="detail-body-tool-info" v-if="flow.flow_type == 3 && maintenanceInfo">
      <div class="title red">维修信息</div>
      <div class="main">
        <div class="text">维修方式：{{ transferMaintenanceMode(maintenanceInfo?.mode) }}</div>
        <div class="text">损坏部位：{{ maintenanceInfo?.damage_part_name }}</div>
        <div class="text">遗失部位：{{ maintenanceInfo?.lose_part_name }}</div>
        <div class="text">维修公司：{{ maintenanceInfo?.maintenance_company }}</div>
        <div class="text">物流单号：{{ maintenanceInfo?.express_delivery_number }}</div>
        <div class="text">快递单照片：</div>
        <div class="images flex-start-center flex-wrap">
          <div v-for="(path, index) in maintenanceInfo?.express_delivery_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
        <div class="text">工具图：</div>
        <div class="images flex-start-center flex-wrap">
          <div v-for="(path, index) in maintenanceInfo?.tool_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
        <div></div>
      </div>
      <div class="detail">
        <tl-button name="工具详情" type="black" @click="navigateTo('toolDetail')"></tl-button>
      </div>
    </div>
    <div class="detail-body-tool-info" v-if="flow.flow_type == 4 && !!checkInfo">
      <div class="title orange">校验信息</div>
      <div class="main">
        <div class="text">校验方式：{{ transferCheckMode(checkInfo?.mode) }}</div>
        <div class="text">校验厂家：{{ checkInfo?.check_company }}</div>
        <div class="text">寄出物流公司：{{ checkInfo?.express_delivery_company }}</div>
        <div class="text">寄出物流单号：{{ checkInfo?.express_delivery_number }}</div>
        <div class="text">回寄时地址：{{ checkInfo?.return_shipping_address }}</div>
        <div class="text">回寄时收货人：{{ checkInfo?.return_shipping_name }}</div>
        <div class="text">回寄时手机号：{{ checkInfo?.return_shipping_phone }}</div>
        <div class="text">寄出快递单照片：</div>
        <div class="images flex-between-center flex-wrap">
          <div v-for="(path, index) in checkInfo?.express_delivery_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
        <div class="text">工具照片：</div>
        <div class="images flex-between-center flex-wrap">
          <div v-for="(path, index) in checkInfo?.tool_img.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
      </div>
    </div>
    <div class="detail-body-tool-info" v-if="flow.flow_type == 4 && !!checkCompletedInfo">
      <div class="title orange">校验完成信息</div>
      <div class="main">
        <div class="text">校验厂家：{{ checkCompletedInfo?.check_supplier }}</div>
        <div class="text">校验时间：{{ checkCompletedInfo?.create_time }}</div>
        <div class="text">校验证书：</div>
        <div class="images flex-between-center flex-wrap">
          <div v-for="(path, index) in checkCompletedInfo?.check_certificate.split(',')" :key="index">
            <el-image :src="UPLOAD_PATH + path" fit="cover" v-if="!!path" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-opreate" v-if="user.user_id === flow?.active_user">
    <div v-if="flow.flow_type == 1">
      <div v-if="[1, 2, 3].includes(flow?.flow_status)" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="showGoForApproval">审批</div>
      </div>
      <div v-if="flow?.flow_status == 4" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createLogisticsInfo')">填写物流信息</div>
      </div>
      <div v-if="flow?.flow_status == 5" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createConfirmReceipt')">确认收货</div>
      </div>
      <div v-if="flow?.flow_status == 6" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn brcw" @click="navigateTo('createDamageRepair')">损坏</div>
        <div class="btn bwcb" @click="borrowCompleted">使用完成</div>
      </div>
    </div>
    <div v-if="flow.flow_type == 2">
      <div v-if="[1, 2, 3].includes(flow?.flow_status)" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="showGoForApproval">审批</div>
      </div>
      <div v-if="flow?.flow_status == 4" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createLogisticsInfo')">填写物流信息</div>
      </div>
      <div v-if="flow?.flow_status == 5" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createConfirmReceipt')">确认收货</div>
      </div>
      <div v-if="flow?.flow_status == 6" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn brcw" @click="navigateTo('createDamageRepair')">损坏</div>
        <div class="btn bwcb" @click="borrowCompleted">使用完成</div>
      </div>
      <div v-if="flow?.flow_status == 10" class="flex-center-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn bgcw" @click="cancelAppointment">取消预约</div>
      </div>
    </div>

    <div v-if="flow.flow_type == 3">
      <div v-if="flow?.flow_status == 1" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn brcw" @click="navigateTo('createDamageRepair')">维修</div>
      </div>
      <div v-if="flow?.flow_status == 2" class="flex-center-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn brcw" @click="navigateTo('createCompletedMaintain')">维修完成</div>
      </div>
      <div v-if="flow?.flow_status == 4" class="flex-center-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createConfirmReceipt')">确认收货</div>
      </div>
    </div>
    <div v-if="flow.flow_type == 4">
      <div v-if="flow?.flow_status == 1" class="flex-center-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn bwcb" @click="navigateTo('createVerifyTool')">校验工具</div>
      </div>
      <div v-if="flow?.flow_status == 2" class="flex-center-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn bwcb" @click="navigateTo('completeVerify')" v-if="checkInfo?.mode == 1">校验完成</div>
        <div class="btn complete" @click="navigateTo('createLogisticsInfo')" v-if="checkInfo?.mode == 2">回寄</div>
      </div>
      <div v-if="flow?.flow_status == 4" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn complete" @click="navigateTo('createConfirmReceipt')">确认收货</div>
      </div>
      <div v-if="flow?.flow_status == 5" class="flex-between-center">
        <div class="btn back" @click="back">返回</div>
        <div class="btn bwcb" @click="navigateTo('completeVerify')">校验完成</div>
      </div>
    </div>
  </div>

  <el-dialog v-model="showStopProcessDialog" width="350" :show-close="false" top="35vh">
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p class="title" :id="titleId" :class="titleClass">终止流程</p>
        <p class="close" @click="close">取消</p>
      </div>
    </template>

    <div class="form">
      <el-form label-position="top" :rules="goForApprovalRules" :model="stopProcessForm" ref="stopProcessForm">
        <el-form-item label="" prop="comments">
          <el-input v-model="stopProcessForm.comments" placeholder="终止说明：" rows="5" type="textarea" />
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-center-center">
      <el-button class="submit-btn" @click="submitStopProcessForm" size="large"> 确认提交 </el-button>
    </div>
  </el-dialog>

  <el-dialog v-model="showModifyUsageTimeDialog" width="350" :show-close="false" top="35vh">
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p class="title" :id="titleId" :class="titleClass">修改使用时间</p>
        <p class="close" @click="close">取消</p>
      </div>
    </template>

    <div class="form">
      <el-form label-position="top" :rules="usageTimeRules" :model="usageTimeForm" ref="usageTimeForm">
        <el-form-item label="预约借调开始时间：" required>
          <el-col :span="22">
            <el-form-item prop="startDate">
              <el-date-picker type="date" placeholder="选择日期" v-model="usageTimeForm.startDate" style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="预约借调结束时间：" required>
          <el-col :span="22">
            <el-form-item prop="endDate">
              <el-date-picker type="date" placeholder="选择日期" v-model="usageTimeForm.endDate" style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-center-center">
      <el-button @click="submitUsageTimeForm" class="submit-btn" size="large"> 确认提交 </el-button>
    </div>
  </el-dialog>
  <el-dialog v-model="showGoForApprovalDialog" width="350" :show-close="false" top="35vh">
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p class="title" :id="titleId" :class="titleClass">流程审批</p>
        <p class="close" @click="close">取消</p>
      </div>
    </template>

    <div class="form">
      <el-form label-position="top" :rules="goForApprovalRules" :model="goForApprovalForm" ref="goForApprovalForm">
        <el-form-item label="" prop="isPass" required>
          <el-radio-group v-model="goForApprovalForm.isPass">
            <el-radio value="true">同意</el-radio>
            <el-radio value="false">不同意</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="remark">
          <el-input v-model="goForApprovalForm.remark" placeholder="备注说明：" rows="5" type="textarea" />
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-center-center">
      <el-button class="submit-btn" @click="submitGoForApprovalForm" size="large"> 确认提交 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import ProcessApi from '@/utils/api/process';
import UserApi from '@/utils/api/user';
import { transferDict } from '@/utils/utils';
import { UPLOAD_PATH } from '@/utils/config';
import ToolApi from '@/utils/api/tool';
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';
export default {
  data() {
    return {
      collapse: '1',
      theme: '',
      user: {},
      flow: {},
      dicts: [],
      maintenanceInfo: null,
      checkInfo: null,
      checkCompletedInfo: null,
      deliveryInfo: null,
      borrowInfo: null,
      takeOverInfo: null,
      toolPartList: [],
      UPLOAD_PATH: '',
      showButton: 111,
      activeName: 'first',
      showStopProcessDialog: false,
      showModifyUsageTimeDialog: false,
      showGoForApprovalDialog: false,
      usageTimeForm: {
        startDate: '',
        endDate: '',
      },
      usageTimeRules: {
        startDate: [{ type: 'date', required: true, message: '请选择预约借调开始时间', trigger: 'change' }],
        endDate: [{ type: 'date', required: true, message: '请选择预约借调结束时间', trigger: 'change' }],
      },
      stopProcessForm: {
        comments: '',
      },
      goForApprovalForm: {
        isPass: '',
        remark: '',
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      const flow_id = this.$route.query?.flow_id;
      if (flow_id) {
        const loadingInstance = this.$loading({ text: '加载中...' });
        const dictStore = useDictStore();
        this.dicts = await dictStore.getDict();
        const userStore = useUserStore();
        this.user = userStore.user;
        const res = await ProcessApi.getFlowDetail(flow_id);
        this.flow = res.data[0];
        this.deliveryInfo = await ProcessApi.getExpressDeliveryInfoByFlowId(flow_id);
        this.borrowInfo = await ProcessApi.getBorrowInfoByFlowId(flow_id);
        this.takeOverInfo = await ProcessApi.getTakeOverInfoByFlowId(flow_id);
        this.UPLOAD_PATH = UPLOAD_PATH;
        this.theme = `status${this.flow.flow_type}`;
        if (this.flow.flow_type === 3) {
          this.maintenanceInfo = (await ToolApi.getFlowMaintenances(flow_id))[0];
          this.toolPartList = await ToolApi.getPartList(this.flow.tool_id);
          const parts = await ProcessApi.getFlowDamageData(flow_id);
          const damage_part_ids = parts.data.filter((v) => v.is_damage).map((v) => v.damage_part);
          const lose_part_ids = parts.data.filter((v) => !v.is_damage).map((v) => v.damage_part);
          if (this.flow.flow_status !== 1) {
            this.maintenanceInfo.damage_part_name = this.toolPartList
              .filter((v) => damage_part_ids.includes(String(v.part_id)))
              .map((v) => v.part_name)
              .join(',');
            this.maintenanceInfo.lose_part_name = this.toolPartList
              .filter((v) => lose_part_ids.includes(String(v.part_id)))
              .map((v) => v.part_name)
              .join(',');
          }
        }
        if (this.flow.flow_type === 4) {
          this.checkInfo = (await ToolApi.getFlowChecks(flow_id))[0];
          this.checkCompletedInfo = (await ToolApi.getCheckCompletes(flow_id))[0];
          console.log(this.checkCompletedInfo);
        }
        loadingInstance.close();
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error',
        });
        this.$router.back();
      }
    },
    transferStatus(key) {
      return key && transferDict(this.dicts, 'tool_flow_status' + this.flow.flow_type, key);
    },
    transferType(key) {
      return key && transferDict(this.dicts, 'tool_action_status', key);
    },
    transferMaintenanceMode(key) {
      return key && transferDict(this.dicts, 'tool_maintenance', key);
    },
    transferCheckMode(key) {
      return key && transferDict(this.dicts, 'tool_check', key);
    },

    back() {
      this.$router.back();
    },
    navigateTo(routerName) {
      this.$router.push({
        name: routerName,
        query: { flow_id: this.flow.flow_id, tool_id: this.flow.tool_id },
      });
    },
    stopProcess() {
      this.showStopProcessDialog = true;
    },
    submitStopProcessForm() {
      this.showStopProcessDialog = false;
    },
    showModifyUsageTime() {
      this.showModifyUsageTimeDialog = true;
    },
    submitUsageTimeForm() {
      this.$refs.usageTimeForm.validate((valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          this.showModifyUsageTimeDialog = false;
        } else {
          // 表单校验不通过
          return false;
        }
      });
    },

    showGoForApproval() {
      this.showGoForApprovalDialog = true;
    },
    async submitGoForApprovalForm() {
      this.$refs.goForApprovalForm.validate(async (valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          ProcessApi.auditFlow({
            flowid: this.flow.flow_id,
            isPass: this.goForApprovalForm.isPass,
            remark: this.goForApprovalForm.remark,
          })
            .then((res) => {
              this.showGoForApprovalDialog = false;
              this.$message({
                message: '审核成功',
                type: 'success',
              });
              this.$router.back();
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        } else {
          // 表单校验不通过
          return false;
        }
      });
    },

    borrowCompleted() {
      this.$confirm('确认工具使用完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '提交中...',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          // 用户点击确定执行的逻辑
          ProcessApi.borrowCompleted(this.flow?.flow_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    if (this.takeOverInfo.tool_status == 4) {
                      this.$message({
                        message: '因工具缺损损坏，现自动创建维修流程请及时处理',
                        type: 'info',
                        duration: 3000,
                        onClose: () => {
                          loading.close();
                          this.$router.back();
                        },
                      });
                    } else {
                      loading.close();
                      this.$router.back();
                    }
                  },
                });
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    async cancelAppointment() {
      this.$confirm('确认取消预约吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.reservationCancel(this.flow?.flow_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '取消预约成功',
                  type: 'success',
                });
                this.$router.back();
              } else {
                this.$message({
                  message: res.message || '取消预约失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '取消预约失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style scoped>
.detail-header {
  width: 100vw;
  height: 10vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
  display: flex;
  padding: 0 2rem;
}

.detail-header .flex-between-center {
  width: 100%;
}

.detail-header .flex-between-center div {
  width: 30%;
}

.detail-header .flex-between-center .return {
  text-align: left;
}

.detail-header .flex-between-center .return img {
  width: 1rem;
}

.detail-header .flex-between-center .search img {
  width: 2rem;
}

.detail-header .flex-between-center .title {
  font-size: 1.8rem;
}

.detail-header .flex-between-center .search p {
  font-size: 1.4rem;
  margin: 0 0.6rem;
}

.detail-body-head {
  padding: 2rem;
  border-bottom: 1px solid #e0e1e1;
}

.detail-body-head .title {
  font-size: 1.4rem;
}

.detail-body-info .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.4rem 2rem;
}

.detail-body-info .tips {
  width: 90vw;
  margin: 0 auto;
  text-align: left;
  font-size: 1.2rem;
  padding: 1.5rem;
  background: #f6f8fb;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.detail-body-tool-info {
  width: 90vw;
  border-radius: 20px;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  background: white;
  margin: 2rem auto;
}

.detail-body-tool-info .title {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e0e1e1;
  text-align: left;
}

.detail-body-tool-info .title.blue {
  color: #0068b7;
}

.detail-body-tool-info .title.green {
  color: #0daa90;
}

.detail-body-tool-info .title.red {
  color: #cd4a5f;
}

.detail-body-tool-info .title.orange {
  color: #f5aa30;
}

.detail-body-tool-info .main {
  padding: 1rem 2rem;
  border-bottom: 1px solid #e0e1e1;
}

.detail-body-tool-info .main .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.5rem 0;
}

.detail-body-tool-info .text .model {
  color: #0068b7;
  font-weight: bold;
}

.detail-body-tool-info .images > div {
  width: 30%;
  margin-bottom: 2vw;
  margin-right: 3%;
}

.detail-body-tool-info .images > div > div {
  width: 25vw;
  height: 25vw;
}

.detail-body-tool-info .tips {
  width: 80vw;
  margin: 0.5rem auto;
  text-align: left;
  font-size: 1.2rem;
  padding: 1.5rem;
  background: #f6f8fb;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.detail-body-tool-info .detail {
  padding: 1rem 2rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.detail-body-tool-info .detail > div {
  max-width: 10rem;
}

.detail-opreate {
  background: white;
  padding: 2rem 5vw;
}

.detail-opreate > div {
  padding: 2rem 0;
}

.detail-opreate > div:first-child {
  /* border-bottom: 1px solid #e0e1e1; */
}

.detail-opreate .btn {
  width: 45%;
  font-size: 1.4rem;
  margin: 0 0.5rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.detail-opreate .btn.back {
  background-color: #a3abb4;
  color: white;
}

.detail-opreate .btn.complete {
  background-color: #0068b7;
  color: white;
}

.detail-opreate .btn.bbcw {
  background-color: white;
  color: #343434;
}

.detail-opreate .btn.bwcb {
  background-color: #343434;
  color: white;
  border: 1px solid #343434;
}

.detail-opreate .btn.brcw {
  background-color: #cd4a5f;
  color: white;
}

.detail-opreate .btn.bgcw {
  background-color: #0daa90;
  color: white;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.dialog-header .title {
  font-size: 18px;
  color: #343434;
}

.dialog-header .close {
  cursor: pointer;
  font-size: 16px;
  color: #343434;
}

.submit-btn {
  background-color: #0068b7;
  /* 设置按钮背景色为蓝色 */
  color: white;
  font-size: 1.4rem;
}

.status1 {
  font-weight: bold;
  color: #0068b7;
}

.status2 {
  font-weight: bold;
  color: #0daa90;
}

.status3 {
  font-weight: bold;
  color: #cd4a5f;
}

.status4 {
  font-weight: bold;
  color: #f5aa30;
}
</style>
