const FRONT_URL = process.env.VUE_APP_FRONTEND_URL

const UPLOAD_PATH = process.env.VUE_APP_UPLOAD_PATH
const APPID = process.env.VUE_APP_WECOM_APPID || 'wxe89bb43b1921f7fe'
const AGENTID = process.env.VUE_APP_WECOM_AGENTID || '1000038'

module.exports = {
  FRONT_URL,
  APPID,
  AGENTID,
  UPLOAD_PATH,
}
