<template>
  <div class="process-top">
    <div class="flex-between-center">
      <div class="return flex-start-center">
        <img src="@/assets/images/return_icon.png" alt="" class="cp" @click=back>

      </div>
      <p class="title">流程列表</p>
      <div class="flex-end-center search">
        <!-- <img src="@/assets/images/search_icon.png" alt="">
        <p class="searchText">搜索</p> -->
      </div>
    </div>
  </div>

  <el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
    <el-tab-pane label="进行中的流程" name="first">
      <Progress ref="first"></Progress>
    </el-tab-pane>
    <el-tab-pane label="我操作的流程" name="second">
      <Operate ref="second"></Operate>
    </el-tab-pane>
    <el-tab-pane label="待办事项" name="third">
      <Todo ref="third"></Todo>
    </el-tab-pane>
    <el-tab-pane label="已过期流程" name="fourth">
      <Expire ref="fourth"></Expire>
    </el-tab-pane>
  </el-tabs>
  <div v-if="user?.positon == 3" class="create-process" @click="navigateTo('createProcess')">
    <p class="plus">+</p>
    <p>新建</p>
  </div>
  <tool-footer activeMenu="process">
  </tool-footer>
</template>

<script>
import UserApi from '@/utils/api/user'
import Progress from '@/components/Process/Progress'
import Todo from '@/components/Process/Todo'
import Operate from '@/components/Process/Operate'
import Expire from '@/components/Process/Expire'
import { useUserStore } from '@/stores/useUserStore'
export default {
  components: {
    Progress,
    Todo,
    Expire,
    Operate
  },
  data () {
    return {
      activeName: 'first',
      user: {}
    };
  },
  created () {
    const userStore = useUserStore();
    this.user = userStore.user;
  },
  mounted () {
    // 使用 $route.params 来访问传递的参数
    const activeName = this.$route.query?.activeName || 'first';
    this.activeName = activeName
    this.$refs[this.activeName].getList()
  },
  methods: {
    handleClick (tab, event) {
      this.$refs[tab.props.name].getList()
    },
    back () {
      this.$router.back();
    },
    navigateTo (route) {
      this.$router.push({ name: route });
    }
  }
}
</script>

<style scoped>
.process-top {
  width: 100vw;
  height: 10vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
  display: flex;
  padding: 0 2rem;
}

.process-top .flex-between-center {
  width: 100%;
}

.process-top .flex-between-center div {
  width: 30%;
}

.process-top .flex-between-center .return {
  text-align: left;
}

.process-top .flex-between-center .return img {
  width: 1rem;
}

.process-top .flex-between-center .search img {
  width: 2rem;
}

.process-top .flex-between-center .title {
  font-size: 1.8rem;
}

.process-top .flex-between-center .search p {
  font-size: 1.4rem;
  margin: 0 0.6rem;
}

:deep(.el-tabs__item) {
  padding: 0 10px !important;
}

:deep(.el-tabs__item.is-active) {
  color: #065adb !important;
}

:deep(.el-tabs__active-bar) {
  background-color: #065adb !important;
}

.create-process {
  position: fixed;
  right: 30px;
  bottom: 100px;
  width: 60px;
  height: 60px;
  background: #0068b7;
  border-radius: 50%;
}

.create-process p {
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.create-process p.plus {
  transform: scale(2);
  font-size: 22px;
  margin-bottom: 4px;
}
</style>