<template>
  <div class="tool-top">
    <div class="flex-between-center">
      <div class="return flex-start-center">
        <img src="@/assets/images/return_icon.png" alt="" class="cp" @click=back>
      </div>
      <p class="title">工具列表</p>
      <div class="flex-end-center search">
        <!-- <img src="@/assets/images/search_icon.png" alt="">
        <p class="searchText">搜索</p> -->
      </div>
    </div>
  </div>

  <el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
    <el-tab-pane label="借调工具" name="first">
      <Transfer ref="first"></Transfer>
    </el-tab-pane>
    <el-tab-pane label="校验中工具" name="second">
      <Verify ref="second"></Verify>
    </el-tab-pane>
    <el-tab-pane label="损坏工具" name="third">
      <Damage ref="third"></Damage>
    </el-tab-pane>
    <el-tab-pane label="我的工具" name="fourth">
      <My ref="fourth"></My>
    </el-tab-pane>
  </el-tabs>

  <tool-footer activeMenu="tool"></tool-footer>
</template>

<script>
import UserApi from '@/utils/api/user'
import Transfer from '@/components/Tool/Transfer'
import Verify from '@/components/Tool/Verify'
import Damage from '@/components/Tool/Damage'
import My from '@/components/Tool/My'
import { useUserStore } from '@/stores/useUserStore'
export default {
  components: {
    Transfer,
    Verify,
    Damage,
    My
  },
  data () {
    return {
      activeName: 'first',
      user: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {
    this.$refs[this.activeName].getList()
  },
  methods: {
    init () {
      const activeName = this.$route.query?.activeName || 'first';
      this.activeName = activeName
      const userStore = useUserStore();
      this.user = userStore.user;
    },
    handleClick (tab, event) {
      this.$refs[tab.props.name].getList()
    },
    back () {
      this.$router.back();
    },
  }
}
</script>

<style>
.tool-top {
  width: 100vw;
  height: 10vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
  display: flex;
  padding: 0 2rem;
}

.tool-top .flex-between-center {
  width: 100%;
}

.tool-top .flex-between-center div {
  width: 30%;
}

.tool-top .flex-between-center .return {
  text-align: left;
}

.tool-top .flex-between-center .return img {
  width: 1rem;
}

.tool-top .flex-between-center .search img {
  width: 2rem;
}

.tool-top .flex-between-center .title {
  font-size: 1.8rem;
}

.tool-top .flex-between-center .search p {
  font-size: 1.4rem;
  margin: 0 0.6rem;
}

.el-tabs__item {
  padding: 0 10px !important;
}

.el-tabs__item.is-active {
  color: #065adb !important;
}

.el-tabs__active-bar {
  background-color: #065adb !important;
}
</style>