<template>
  <div>
    <header>
      <!-- 这里可以放置页眉 -->
    </header>

    <main>
      <slot></slot>
    </main>
    <footer>

    </footer>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data () {
    return {
      activeMenu: '' // 存储当前活动菜单的路由名称
    };
  },
  created () {
  },
  methods: {
    navigateTo (routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 0 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid #d8d8d8;
}

.footer-menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-menu i {
  margin-bottom: 10px; /* 图标和文字之间的间距 */
}

.active {
  color: #0068b7; /* 活动菜单的文字颜色 */
}
</style>
