import request from '@/utils/axios';

class ToolApi {

  // 获取流程统计数据[Borrow, Check, Damage, My]
  static async getToolCountData () {
    return await request.get('/Tool_Info/GetToolCountData')
  }

  // 获取借调中的数据
  static async getBorrowData ({ region, first_cat, second_cat, keyword }) {
    return await request.get('/Tool_Info/GetBorrowData', { region, first_cat, second_cat, keyword })
  }

  // 获取校验中的数据
  static async getCheckData ({ region, first_cat, second_cat, keyword }) {
    return await request.get('/Tool_Info/GetCheckData', { region, first_cat, second_cat, keyword })
  }

  // 获取损坏工具的数据
  static async getDamageData ({ region, first_cat, second_cat, keyword }) {
    return await request.get('/Tool_Info/GetDamageData', { region, first_cat, second_cat, keyword })
  }

  // 获取我的工具数据
  static async getMyData ({ region, first_cat, second_cat, keyword }) {
    return await request.get('/Tool_Info/GetMyData', { region, first_cat, second_cat, keyword })
  }

  // 获取工具下所有部件
  static async getPartList (toolid) {
    return await request.get('/Tool_Info/GetPartList', { toolid })
  }

  // 获取可借调工具
  static async getBorrowTools ({ isBorrow, first_cat, second_cat, name }) {
    return await request.get('/Tool_Info/GetBorrowTools', { isBorrow, first_cat, second_cat, name })
  }

  // 获取工具维修记录
  static async getToolMaintenances (toolid) {
    return await request.get('/Tool_Info/GetToolMaintenances', { toolid })
  }

  // 获取工具校验记录
  static async getToolChecks (toolid) {
    return await request.get('/Tool_Info/GetToolChecks', { toolid })
  }

  // 获取工具详情
  static async getToolDetail (toolid) {
    return await request.get('/Tool_Info/GetToolDetail', { toolid })
  }

  // 获取流程中校验详情
  static async getFlowChecks (flowid) {
    return await request.get('/Tool_Info/GetFlowChecks', { flowid })
  }

  // 获取流程中维修详情
  static async getFlowMaintenances (flowid) {
    return await request.get('/Tool_Info/GetFlowMaintenances', { flowid })
  }

  // 获取流程中校验完成详情
  static async getCheckCompletes (flowid) {
    return await request.get('/Tool_Info/GetCheckCompletes', { flowid })
  }

  // 获取流程中维修完成详情
  static async getMaintenanceCompletes (flowid) {
    return await request.get('/Tool_Info/GetMaintenanceCompletes', { flowid })
  }


}

export default ToolApi