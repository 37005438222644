import { AGENTID, APPID, FRONT_URL } from "./config"

export function transferDict (allDict, type, key) {
  const dict = allDict?.find(v => v.dicNo === type)?.data
  return dict?.find(v => v.key == key)?.value
}

export function getDict (allDict, type) {
  const dict = allDict?.find(v => v.dicNo === type)?.data
  return dict || []
}


export function generateAuthUrl () {
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${FRONT_URL}& response_type= code & scope=snsapi_base & agentid=${AGENTID}& connect_redirect=1#wechat_redirect`
}