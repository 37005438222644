import UserApi from '@/utils/api/user';
import { defineStore } from 'pinia';

export const useDictStore = defineStore('useDict', {

  state: () => ({
    dicts: null,
  }),

  getters: {
  },

  actions: {
    async getDict () {
      if (this.dicts) return this.dicts
      this.dicts = await UserApi.getToolDict()
      return this.dicts
    },
  },
});
