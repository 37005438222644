<template>
  <tl-header title="填写物流信息"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="物流公司" prop="ExpressCompany" required>
        <el-col>
          <el-input v-model="form.ExpressCompany" placeholder="请填写物流公司">
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="物流单号" prop="ExpressNumber" required>
        <el-col>
          <el-input v-model="form.ExpressNumber" placeholder="请填写物流单号">
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="快递单图片" prop="DeliveryImgs" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleDeliveryChange"
          :file-list="form.DeliveryImgs" multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed"
          list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="邮寄工具图片" prop="ToolImgs" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleToolChange" :file-list="form.ToolImgs"
          multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item prop="checked" required>
        <el-checkbox v-model="form.checked" /> 请查看《注意事项》内容，并确保工具满足要求。
      </el-form-item>
    </el-form>
  </div>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'

export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '填写物流信息',
      form: {
        flow_id: '',
        ExpressCompany: '',
        ExpressNumber: '',
        checked: false,
        DeliveryImgs: [],
        ToolImgs: []
      },
      rules: {
        ExpressCompany: [
          { required: true, message: '请输入物流公司', trigger: 'blur' }
        ],
        ExpressNumber: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
        ]
      },
      limit: 4
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.$route.query?.flow_id) {
        this.form.flow_id = this.$route.query?.flow_id
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error'
        })
        this.$router.back();
      }
    },
    back () {
      this.$router.back();
    },
    beforeUpload (file) {
      // 在上传之前的处理，例如：文件类型、大小限制等
      console.log('beforeUpload', file);
    },
    handleError (err, file, fileList) {
      // 上传失败的处理
      console.error('handleError', err, file, fileList);
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    handleDeliveryChange (file, fileList) {
      this.form.DeliveryImgs = fileList;
    },
    handleToolChange (file, fileList) {
      this.form.ToolImgs = fileList;
    },
    submitForm () {
      this.$refs.myForm.validate(async valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          ProcessApi.expressDelivery(this.form).then(res => {

            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.back();
          }).catch(err => {
            this.$message({
              message: err.message || '提交成功',
              type: 'error'
            })
          })
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
  min-height: calc(90vh - 14rem);
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}

:deep(.el-checkbox__inner) {
  margin-right: 1rem;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>