<template>
  <div class="selects">
    <el-form>
      <el-row type="flex" justify="start" :gutter="10">
        <el-col :span="7">
          <el-form-item label="">
            <el-select v-model="selectForm.first_cat" placeholder="大类" @change="changeFirstCat" clearable>
              <el-option v-for="item in first_cat_list" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="">
            <el-select v-model="selectForm.second_cat" placeholder="小类" @change="change" clearable>
              <el-option v-for="item in second_cat_list" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="">
            <el-input v-model="selectForm.name" placeholder="工具名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="">
            <el-button type="primary" @click="searchTools"> 搜索 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

  <div class="transferForm">
    <el-form label-position="top" :rules="rules" :model="transferForm" ref="transferForm">
      <el-form-items prop="ToolId" required>
        <div class="collapse" v-if="tools.length > 0">
          <el-radio-group v-model.number="transferForm.ToolId">
            <el-collapse accordion v-for="tool in tools" :key="tool.tool_id">
              <el-collapse-item>
                <template v-slot:title>
                  <div class="title"><el-radio :value="tool.tool_id" />{{ tool.name }}</div>
                </template>
                <div class="text">订购号：{{ tool.asset_number }}</div>
                <div class="text">大区：{{ transferDict('tool_region', tool.region) }}</div>
                <div class="text">小区：{{ transferDict('tool_region', tool.small_region) }}</div>
                <div class="text">大类：{{ transferDict('tool_cat', tool.first_cat) }}</div>
                <div class="text">小类：{{ transferDict('tool_cat', tool.second_cat) }}</div>
              </el-collapse-item>
            </el-collapse>
          </el-radio-group>
        </div>
      </el-form-items>
      <el-divider></el-divider>
      <el-form-item label="借调开始时间：" required>
        <el-col>
          <el-form-item prop="Start">
            <el-date-picker
              type="date"
              placeholder="选择开始日期"
              v-model="transferForm.Start"
              style="width: 100%"
              value-format="YYYYMMDD"
              :disabled-date="disabledStartDate"
              @change="handelChangeStartTime"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="借调结束时间：" required>
        <el-col>
          <el-form-item prop="End">
            <el-date-picker type="date" placeholder="选择结束日期" v-model="transferForm.End" style="width: 100%" value-format="YYYYMMDD" :disabled-date="disabledEndDate"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="备注：" prop="Remark">
        <el-col>
          <el-input v-model="transferForm.Remark" placeholder="备注说明：" rows="5" type="textarea" />
        </el-col>
      </el-form-item>
      <el-form-item label="借调工程师：" prop="UserId" required>
        <el-col>
          <el-select v-model="transferForm.UserId" placeholder="请选择借调工程师">
            <el-option v-for="borrower in borrowers" :key="borrower.value" :label="borrower.label" :value="borrower.value"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="收件人：" prop="receiver" required>
        <el-col>
          <el-input v-model="transferForm.receiver" placeholder="请填写收件人姓名"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系电话：" prop="Tel" required>
        <el-col>
          <el-input v-model="transferForm.Tel" placeholder="请填写电话"> </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="收件地址：" prop="Address" required>
        <el-col>
          <el-input v-model="transferForm.Address" placeholder="请填写地址"> </el-input>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">提交</div>
  </div>
</template>

<script>
import ProcessApi from '@/utils/api/process'
import ToolApi from '@/utils/api/tool'
import UserApi from '@/utils/api/user'
import { transferDict, getDict } from '@/utils/utils'
import moment from 'moment'
import { useDictStore } from '@/stores/useDictStore'
import { useUserStore } from '@/stores/useUserStore'

export default {
  name: 'CreateTransfer',
  data() {
    return {
      title: '借调工具',
      tools: [],
      borrowers: [],
      selectForm: {
        first_cat: '',
        second_cat: '',
        name: '',
        isBorrow: true,
      },
      transferForm: {
        ToolId: '',
        Start: '',
        End: '',
        Remark: '',
        UserId: '',
        UserName: '',
        receiver: '',
        Tel: '',
        Address: '',
        IsBorrow: true,
      },
      first_cat_list: [],
      second_cat_list: [],
      dicts: [],
      rules: {
        ToolId: [{ required: true, message: '请选择借调工具', trigger: 'change' }],
        Start: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
        End: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
        UserId: [{ required: true, message: '请选择借调工程师', trigger: 'blur' }],
        receiver: [{ required: true, message: '请输入收件人姓名', trigger: 'blur' }],
        Tel: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        Address: [{ required: true, message: '请输入收件地址', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const userStore = useUserStore()
      const user = userStore.user
      const res = await UserApi.getToolUserByDispatcher(user.user_id)
      const borrowers = []
      for (const key in res.data) {
        borrowers.push({
          label: res.data[key],
          value: key,
        })
      }
      this.borrowers = borrowers
      const dictStore = useDictStore()
      this.dicts = await dictStore.getDict()

      this.first_cat_list = getDict(this.dicts, 'tool_cat').filter((item) => !item.parentId)
    },
    back() {
      this.$router.back()
    },
    transferDict(dictName, key) {
      return key && transferDict(this.dicts, dictName, key)
    },
    async searchTools() {
      const tools = await ToolApi.getBorrowTools(this.selectForm)
      this.tools = tools
    },
    async submitForm() {
      console.log('提交表单:', this.transferForm)
      this.$refs.transferForm.validate(async (valid) => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          this.transferForm.UserName = this.borrowers.find((v) => v.value == this.transferForm.UserId)?.label
          ProcessApi.borrow(this.transferForm)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                })
                this.$router.back()
              } else {
                this.$message({
                  message: res.message || '服务器异常',
                  type: 'error',
                })
              }
            })
            .catch((err) => {
              this.$message({
                message: err?.response?.data?.message || '服务器异常',
                type: 'error',
              })
            })
        } else {
          // 表单校验不通过
          return false
        }
      })
    },
    async change() {
      console.log(this.transferForm)
    },

    disabledStartDate(time) {
      return moment(time).isBefore(moment().startOf('day'))
    },
    disabledEndDate(time) {
      return moment(time).isBefore(moment(this.transferForm.Start).startOf('day'))
    },
    handelChangeStartTime(val) {
      this.transferForm.End = ''
    },
    changeFirstCat(value) {
      if (!value) {
        this.second_cat_list = []
        this.selectForm.second_cat = ''
      } else {
        this.second_cat_list = getDict(this.dicts, 'tool_cat').filter((item) => item.parentId == value)
        this.selectForm.second_cat = ''
      }
    },
  },
}
</script>

<style scoped>
:deep(.el-select__wrapper) {
  border-radius: 20px !important;
}

.selects {
  width: 90vw;
  margin: 0 auto;
}

.transferForm {
  width: 80vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate > div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}

.collapse {
  margin: 1rem auto;
}

.el-radio-group {
  width: 100%;
}

.collapse > div > div {
  margin: 5px 0;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  width: 100%;
}

.collapse .title {
  font-size: 1.4rem;
  text-align: left;
  padding: 0.2rem 2rem;
  font-weight: bold;
}

.collapse .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.2rem 2rem;
}
</style>
