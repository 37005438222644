// main.js or router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Process from '@/views/Process/Process.vue';
import ProcessDetail from '@/views/Process/Detail.vue';
import CreateProcess from '@/views/Process/CreateProcess.vue';
import CreateLogisticsInfo from '@/views/Process/CreateLogisticsInfo.vue';
import CreateConfirmReceipt from '@/views/Process/CreateConfirmReceipt.vue';
import CreateConfirmReceiptOwner from '@/views/Process/CreateConfirmReceiptOwner.vue';
import CreateVerifyTool from '@/views/Process/CreateVerifyTool.vue';
import CompleteVerify from '@/views/Process/CompleteVerify.vue';
import CreateDamageRepair from '@/views/Process/CreateDamageRepair.vue';
import CreateReturnShippingInfo from '@/views/Process/CreateReturnShippingInfo.vue';
import CreateCompletedMaintain from '@/views/Process/CreateCompletedMaintain.vue';
import Tool from '@/views/Tool/Tool.vue';
import ToolDetail from '@/views/Tool/Detail.vue';
import CreateMaintain from '@/views/Tool/CreateMaintain.vue';
import CreateVerify from '@/views/Tool/CreateVerify.vue';
import Stocktaking from '@/views/Tool/Stocktaking.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: []
  },
  {
    path: '/process',
    name: 'process',
    component: Process,
    props: true
  },
  {
    path: '/process/detail',
    name: 'processDetail',
    component: ProcessDetail,
  },
  {
    path: '/process/logisticsInfo/create',
    name: 'createLogisticsInfo',
    component: CreateLogisticsInfo,
  },
  {
    path: '/process/confirmReceipt/create',
    name: 'createConfirmReceipt',
    component: CreateConfirmReceipt,
  },
  {
    path: '/process/confirmReceiptOwner/create',
    name: 'createConfirmReceiptOwner',
    component: CreateConfirmReceiptOwner,
  },
  {
    path: '/process/verifyTool/create',
    name: 'createVerifyTool',
    component: CreateVerifyTool,
  },
  {
    path: '/process/verifyTool/complete',
    name: 'completeVerify',
    component: CompleteVerify,
  },
  {
    path: '/process/damageRepair/create',
    name: 'createDamageRepair',
    component: CreateDamageRepair,
  },
  {
    path: '/process/returnShippingInfo/create',
    name: 'createReturnShippingInfo',
    component: CreateReturnShippingInfo,
  },
  {
    path: '/process/maintain/completed',
    name: 'createCompletedMaintain',
    component: CreateCompletedMaintain,
  },
  {
    path: '/process/create',
    name: 'createProcess',
    component: CreateProcess,
  },
  {
    path: '/tool',
    name: 'tool',
    component: Tool,
    props: true
  },
  {
    path: '/tool/detail',
    name: 'toolDetail',
    component: ToolDetail,
  },
  {
    path: '/tool/maintain/create',
    name: 'createMaintain',
    component: CreateMaintain,
  },
  {
    path: '/tool/verify/create',
    name: 'createVerify',
    component: CreateVerify,
  },
  {
    path: '/tool/stocktaking',
    name: 'stocktaking',
    component: Stocktaking,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
