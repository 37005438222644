// 1.导入自定义公用组件
import Tag from './Tag';
import ToolFooter from './ToolFooter';
import Attachment from './Attachment';
import TlHeader from './ToolHeader';
import TlCard from './ToolCard';
import TlButton from './ToolButton';
import PsCard from './ProcessCard';
const components = {
  Tag,
  ToolFooter,
  Attachment,
  TlHeader,
  TlCard,
  PsCard,
  TlButton
};
const install = (app) => { // 核心要定义一个install方法
  Object.keys(components).forEach(key => {
    app.component(components[key]['name'], components[key])
  });
};
const UI = {
  install
};
export default UI;
