import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/stores/useUserStore'

class Request {
  constructor() {
    if (!Request.instance) {
      this.api = axios.create({
        baseURL: '/api',
      })

      // 请求拦截器
      this.api.interceptors.request.use(
        (config) => {
          // 在请求发送之前做一些处理
          const userStore = useUserStore()
          const token = userStore.token
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )

      // 响应拦截器
      this.api.interceptors.response.use(
        (response) => {
          return response.data
        },
        (error) => {
          // 处理响应错误
          console.error('API error:', error)
          if (error.response && error.response.status === 401) {
            ElMessage({
              message: '未登录',
              type: 'error',
            })
            const userStore = useUserStore()
            userStore.clear()
            window.location.href = '/'
            return
          }
          return Promise.reject(error)
        }
      )

      Request.instance = this
    }

    return Request.instance
  }

  // 判断是否需要转换为 FormData
  isFormData(data) {
    return Object.values(data).some((value) => value instanceof File || value instanceof Blob)
  }

  // 构建 FormData 对象
  buildFormData(data) {
    const formData = new FormData()
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            if (item.raw && (item.raw instanceof File || item.raw instanceof Blob)) {
              formData.append(`${key}`, item.raw)
            } else {
              formData.append(`${key}`, item)
            }
          })
        } else {
          formData.append(key, data[key])
        }
      }
    }
    return formData
  }

  // 通用请求方法
  request(method, url, data = {}, params = {}) {
    return this.api({
      method,
      url,
      data,
      params,
    })
  }

  // GET 请求
  get(url, params) {
    return this.request('get', url, {}, params)
  }

  // POST 请求
  post(url, data) {
    console.log('data', data)
    return this.request('post', url, this.buildFormData(data))
  }

  // PUT 请求
  put(url, data) {
    return this.request('put', url, this.buildFormData(data))
  }

  // DELETE 请求
  delete(url, params) {
    return this.request('delete', url, {}, params)
  }
}

const request = new Request()

export default request
