<template>
  <div class="header">
    <div class="flex-between-center">
      <div class="return flex-start-center">
        <img src="@/assets/images/return_icon.png" alt="" class="cp" @click=back>
      </div>
      <p class="title">{{title}}</p>
      <div class="flex-end-center search">
        <!-- <p class="searchText">查看流程</p> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TlHeader',

  props: {
    title: String
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.header {
  width: 100vw;
  height: 10vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
  display: flex;
  padding: 0 2rem;
}

.header .flex-between-center {
  width: 100%;
}

.header .flex-between-center div {
  width: 30%;
}

.header .flex-between-center .return {
  text-align: left;
}

.header .flex-between-center .return img {
  width: 1rem;
}

.header .flex-between-center .search img {
  width: 2rem;
}

.header .flex-between-center .title {
  font-size: 1.8rem;
}

.header .flex-between-center .search p {
  font-size: 1.4rem;
  margin: 0 0.6rem;
}
</style>