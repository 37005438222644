<template>
  <div>
    <tl-header title="新建流程"></tl-header>
    <el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
      <el-tab-pane label="借调工具" name="transfer">
        <CreateTransfer></CreateTransfer>
      </el-tab-pane>
      <el-tab-pane label="预约工具" name="appointment">
        <CreateAppointment></CreateAppointment>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CreateTransfer from '@/components/Process/CreateTransfer'
import CreateAppointment from '@/components/Process/CreateAppointment'
import UserApi from '@/utils/api/user'
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';

export default {
  name: 'CreateProcess',
  components: {
    CreateAppointment,
    CreateTransfer,
  },
  props: {},
  data () {
    return {
      title: '新建流程',
      activeName: 'transfer',
      dicts: []
    }
  },
  created () {
    this.checkPermission()
    this.getToolDict()
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    async getToolDict () {
      const dictStore = useDictStore();
      this.dicts = await dictStore.getDict();
    },
    checkPermission () {
      const userStore = useUserStore();
      const user = userStore.user;
      if (user.positon != 3) {
        this.$message({
          message: '无权限',
          type: 'error'
        })
        this.$router.back();
      }
      return
    }
  },
}
</script>

<style scoped>
:deep(.el-tabs__item) {
  padding: 25px 10px !important;
  font-size: 1.4rem;
}

:deep(.el-tabs__item.is-active) {
  color: #065adb !important;
}

:deep(.el-tabs__active-bar) {
  background-color: #065adb !important;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate>div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>