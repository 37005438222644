import { createApp } from 'vue'
import App from './App.vue'
import route from './route'
import ElementPlus from 'element-plus'
import './assets/css/style.css'
import 'element-plus/dist/index.css'
import UI from './components/UI'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPersist)

createApp(App).use(pinia).use(route).use(ElementPlus).use(UI).mount('#app')
console.log('当前版本：', process.env.VUE_APP_VERSION)
