<template>
  <tl-header title="新建校验"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="维修时间" required>
        <el-col :span="22">
          <el-form-item prop="date">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="校验供应商" prop="supplier">
        <el-col :span="22">
          <el-input v-model="form.supplier" placeholder="请填写名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="校验证书" prop="attachments" required>
        <el-upload class="upload-demo" action="/upload" :before-upload="beforeUpload" :on-success="handleSuccess" :on-error="handleError" :file-list="form.attachments" multiple :limit="3" accept=".jpg,.png,.pdf" :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
      <el-form-item label="下次校验时间" required>
        <el-col :span="22">
          <el-form-item prop="date">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.nextVerifyDate" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">保存</div>
  </div>
</template>

<script>

export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '新建维修',
      form: {
        supplier: '',
        date: '',
        nextVerifyDate: '',
        attachments: [
          { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
          { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
          { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
          { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
        ]
      },
      rules: {
        date: [
          { type: 'date', required: true, message: '请选择维修时间', trigger: 'change' }
        ],
        supplier: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        nextVerifyDate: [
          { type: 'date', required: true, message: '请选择下次校验时间', trigger: 'change' }
        ],
      },
      limit: 4
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.back();
    },
    beforeUpload (file) {
      // 在上传之前的处理，例如：文件类型、大小限制等
      console.log('beforeUpload', file);
    },
    handleSuccess (response, file, fileList) {
      // 上传成功的处理
      console.log('handleSuccess', response, file, fileList);
    },
    handleError (err, file, fileList) {
      // 上传失败的处理
      console.error('handleError', err, file, fileList);
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    submitForm () {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate > div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>