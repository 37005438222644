<template>
  <div class="btn" :class="className">
    {{ name }}
  </div>
</template>

<script>

export default {
  name: 'TlButton',
  props: {
    name: String,
    type: String,
  },
  data () {
    return {
      className: '',
      btnEnum: {
        white: 'white-btn',
        blue: 'blue-btn',
        black: 'black-btn'
      }
    }
  },
  created () {
    this.className = this.btnEnum[this.type]
  },
  methods: {

  }
}
</script>

<style scoped>
.btn {
  border-radius: 20px;
  padding: 0.6rem 2rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
  white-space: nowrap;
}
.white-btn {
  background: white;
  border: 1px solid black;
}

.blue-btn {
  background: #0068b7;
  color: white;
}

.black-btn {
  background: black;
  color: white;
}
</style>