<template>
  <tl-header title="新建维修"></tl-header>
  <div class="form">
    <el-form label-position="top" :rules="rules" :model="form" ref="myForm">
      <el-form-item label="维修时间" required>
        <el-col :span="22">
          <el-form-item prop="date">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="损坏部位：选择工具部件（多选）" prop="damagedPart" required>
        <el-col :span="22">
          <el-select v-model="form.damagedPart" placeholder="请选择工具部件" multiple>
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="损失部位：选择工具部件（多选）" prop="losedPart" required>
        <el-col :span="22">
          <el-select v-model="form.losedPart" placeholder="请选择工具部件" multiple>
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="维修供应商" prop="supplier">
        <el-col :span="22">
          <el-input v-model="form.supplier" placeholder="请填写名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="维修价格" prop="price">
        <el-col :span="22">
          <el-input v-model.number="form.price" placeholder="请填写价格">
            <template #append>
              <div>￥</div>
            </template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="附件上传" prop="maintenance_file" required>
        <el-upload class="upload-demo" :auto-upload="false" :on-change="handleAttachmentChange" :file-list="form.maintenance_file" multiple :limit="4" accept=".jpg,.png,.pdf" :on-exceed="handleExceed" list-type="picture-card">
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
  <el-divider></el-divider>
  <div class="operate flex-evenly-center">
    <div class="return" @click="back">返回</div>
    <div class="save" @click="submitForm">保存</div>
  </div>
</template>

<script>
import ToolApi from '@/utils/api/tool'
import ProcessApi from '@/utils/api/process'

export default {
  name: '',

  props: {
  },
  data () {
    return {
      title: '新建维修',
      form: {
        supplier: '',
        date: '',
        damagedPart: [],
        losedPart: [],
        price: '',
        maintenance_file: []
      },
      rules: {
        date: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        supplier: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
          { type: 'number', message: '价格必须为数字值', trigger: 'blur' }
        ]
      },
      limit: 4
    }
  },
  created () {
  },
  methods: {
    back () {
      this.$router.back();
    },
    handleExceed (files, fileList) {
      // 上传文件超过限制时的处理
      this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    handleAttachmentChange (file, fileList) {
      this.form.maintenance_file = fileList;
    },
    submitForm () {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          ProcessApi.addMaintenance(this.form).then(res => {
            if (res.status) {
              this.$message({
                message: '取消预约成功',
                type: 'success'
              })
              this.$router.back();
            } else {
              this.$message({
                message: res.message || '取消预约失败',
                type: 'error'
              })
            }
          }).catch(err => {
            this.$message({
              message: err.message || '取消预约失败',
              type: 'error'
            })
          })
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.form {
  width: 90vw;
  margin: 2rem auto;
}

.operate {
  background-color: white;
  padding: 3rem 5%;
}

.operate > div {
  width: 40%;
  font-size: 1.4rem;
  margin: 0 1rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.operate .return {
  background-color: #a3abb4;
  color: white;
}

.operate .save {
  background-color: #0068b7;
  color: white;
}
</style>
<style>
.el-form-item__label {
  font-size: 1.4rem !important;
}

.el-upload-list__item,
.el-upload--picture-card {
  width: 8rem !important;
  height: 8rem !important;
}
</style>