<template>
  <div class="card">
    <div class="head flex-between-center" :class="theme" @click="navigateTo()">
      <div class="title">
        {{ flow?.tool_name }}
      </div>
      <div class="tags flex-start-center">
        <tag :name="transferType(flow?.flow_type)"></tag>
      </div>
    </div>
    <div class="info" @click="navigateTo()">
      <div class="flex-start-center">
        <div class="label">所属大区：</div>
        <div class="value">{{ flow?.region_name }}</div>
      </div>
      <div class="flex-start-center">
        <div class="label"> 工具归属者：</div>
        <div class="value"> {{flow?.t_ownername}}</div>
      </div>
      <div class="flex-start-center" v-if="flow.flow_type == 1 || flow.flow_type == 2">
        <div class="label">使用时间：</div>
        <div class="value">{{new Date(flow?.use_start_time).toLocaleDateString()}}～{{new Date(flow?.use_end_time).toLocaleDateString()}}</div>
      </div>
      <div class="flex-start-center">
        <div class="label">当前使用者：</div>
        <div class="value">{{flow?.t_username}}</div>
      </div>
      <div class="flex-start-center" v-if="flow.flow_type == 1 || flow.flow_type == 2">
        <div class="label">预约借调者：</div>
        <div class="value">{{flow?.t_secondname || '无'}}</div>
      </div>
      <div class="process-info">
        <div class="flex-start-center">
          <div class="label">发起者：</div>
          <div class="value">{{ flow?.f_username }}</div>
        </div>
        <div class="flex-start-center">
          <div class="label">发起时间：</div>
          <div class="value">{{ flow?.create_time }}</div>
        </div>
        <div class="flex-start-center">
          <div class="label">流程状态：</div>
          <div class="value" :class="theme"> {{transferStatus(flow?.flow_status)}}</div>
        </div>
      </div>
    </div>
    <div class="operate" v-show="(flow?.flow_type == 1 || flow?.flow_type == 2) && [1,2,3].includes(flow?.flow_status)">
      <tl-button name="修改使用时间" type="black" v-if="false" @click.stop="showModifyUsageTime" />
      <tl-button name="去审批" type="blue" v-if="[1,2,3].includes(flow?.flow_status)" @click.stop="showGoForApproval" />
    </div>
    <el-dialog v-model="showModifyUsageTimeDialog" width="350" :show-close="false" top="35vh">
      <template #header="{ close, titleId, titleClass }">
        <div class="dialog-header">
          <p class="title" :id="titleId" :class="titleClass">修改使用时间</p>
          <p class="close" @click="close">
            取消
          </p>
        </div>
      </template>

      <div class="form">
        <el-form label-position="top" :rules="usageTimeRules" :model="usageTimeForm" ref="usageTimeForm">
          <el-form-item label="预约借调开始时间：" required>
            <el-col :span="22">
              <el-form-item prop="startDate">
                <el-date-picker type="date" placeholder="选择日期" v-model="usageTimeForm.startDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="预约借调结束时间：" required>
            <el-col :span="22">
              <el-form-item prop="endDate">
                <el-date-picker type="date" placeholder="选择日期" v-model="usageTimeForm.endDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-center-center">
        <el-button @click="submitUsageTimeForm" class="submit-btn " size="large">
          确认提交
        </el-button>
      </div>
    </el-dialog>

    <el-dialog v-model="showGoForApprovalDialog" width="350" :show-close="false" top="35vh">
      <template #header="{ close, titleId, titleClass }">
        <div class="dialog-header">
          <p class="title" :id="titleId" :class="titleClass">流程审批</p>
          <p class="close" @click="close">
            取消
          </p>
        </div>
      </template>

      <div class="form">
        <el-form label-position="top" :rules="goForApprovalRules" :model="goForApprovalForm" ref="goForApprovalForm">
          <el-form-item label="" prop="isPass" required>
            <el-radio-group v-model="goForApprovalForm.isPass">
              <el-radio value="true">同意</el-radio>
              <el-radio value="false">不同意</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" prop="remark">
            <el-input v-model="goForApprovalForm.remark" placeholder="备注说明：" rows="5" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-center-center">
        <el-button class="submit-btn " @click="submitGoForApprovalForm" size="large">
          确认提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { transferDict } from '@/utils/utils'
import ProcessApi from '@/utils/api/process'
export default {
  name: 'PsCard',
  props: {
    flow: Object,
    dicts: Array,
  },
  data () {
    return {
      theme: '',
      showBtn: false,
      showModifyUsageTimeDialog: false,
      showGoForApprovalDialog: false,
      usageTimeForm: {
        startDate: '',
        endDate: ''
      },
      usageTimeRules: {
        startDate: [
          { type: 'date', required: true, message: '请选择预约借调开始时间', trigger: 'change' }
        ],
        endDate: [
          { type: 'date', required: true, message: '请选择预约借调结束时间', trigger: 'change' }
        ],
      },
      goForApprovalForm: {
        isPass: '',
        remark: ''
      },
      goForApprovalRules: [{
        action: [{
          required: true, message: '请选择审批结果', trigger: 'change'
        }]
      }]

    }
  },
  created () {
  },
  mounted () {
    this.theme = `status${this.flow.flow_type}`
  },
  methods: {
    async change () {
    },
    transferStatus (key) {
      return key && transferDict(this.dicts, 'tool_flow_status' + this.flow.flow_type, key)
    },
    transferType (key) {
      return key && transferDict(this.dicts, 'tool_action_status', key)
    },

    navigateTo () {
      this.$router.push({ name: 'processDetail', query: { flow_id: this.flow?.flow_id, } },);
    },
    showModifyUsageTime () {
      this.showModifyUsageTimeDialog = true
    },
    showGoForApproval () {
      this.showGoForApprovalDialog = true
    },
    submitUsageTimeForm () {
      this.$refs.usageTimeForm.validate(valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          console.log('提交表单:', this.form);
          this.showModifyUsageTimeDialog = false
        } else {
          // 表单校验不通过
          return false;
        }
      });
    },
    async submitGoForApprovalForm () {
      this.$refs.goForApprovalForm.validate(async valid => {
        if (valid) {
          // 表单校验通过，执行提交操作
          // 例如：调用接口提交数据
          await ProcessApi.auditFlow({
            flowid: this.flow.flow_id,
            isPass: this.goForApprovalForm.isPass,
            remark: this.goForApprovalForm.remark
          })
          this.showGoForApprovalDialog = false;
          this.$parent.getList();
        } else {
          // 表单校验不通过
          return false;
        }
      });
    }

  }
}
</script>

<style scoped>
.card {
  width: 90vw;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  border-radius: 20px;
  background: white;
}

.card > .status1 {
  border-bottom: 0.3rem solid #00b0ec;
}
.card > .status2 {
  border-bottom: 0.3rem solid #0daa90;
}

.card > .status3 {
  border-bottom: 0.3rem solid #ff8da0;
}

.card > .status4 {
  border-bottom: 0.3rem solid #feb132;
}

.card > .gray {
  border-bottom: 0.3rem solid #e9e9e9;
}

.head {
  padding: 2rem;
}

.head .title {
  font-size: 1.4rem;
}

.info {
  padding: 2rem;
  font-size: 1.2rem;
}

.info > div {
  margin-bottom: 1rem;
}

.info .process-info {
  margin: 0 auto;
  text-align: left;
  font-size: 1.2rem;
  padding: 1.2rem;
  background: #f6f8fb;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.info .process-info > div {
  margin-bottom: 0.6rem;
}

.info .process-info .status1 {
  font-weight: bold;
  color: #0068b7;
}
.info .process-info .status2 {
  font-weight: bold;
  color: #0daa90;
}
.info .process-info .status3 {
  font-weight: bold;
  color: #cd4a5f;
}
.info .process-info .status4 {
  font-weight: bold;
  color: #f5aa30;
}

.operate {
  border-top: 1px solid #e1e1e1;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.dialog-header .title {
  font-size: 18px;
  color: #343434;
}

.dialog-header .close {
  cursor: pointer;
  font-size: 16px;
  color: #343434;
}

.submit-btn {
  background-color: #0068b7;
  /* 设置按钮背景色为蓝色 */
  color: white;
  font-size: 1.4rem;
}
</style>